import React from 'react';
import Carousel from '@nerdwallet/react-carousel';
import FolderTabContainer from '../folder-tab-container/folder-tab-container';
import styles from './articles.module.scss';
import { CenteredContainer } from '@nerdwallet/currency';
import SegmentImpression from '~/app/components/segment-impression';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import { useFeatureFlag } from '@nerdwallet/features';
import { FEATURE_FLAGS } from '~/app/lib/constants/feature-flags';
import { useQuery } from '@nerdwallet/apollo';
import { logGqlError } from '~/app/lib/logger-utils';
import {
  NewsArticlesQuery,
  NewsArticlesQueryVariables,
  NewsArticlesDocument,
} from '~/generated-graphql/generated-types';
import ArticleCard from './article-card';
import CarouselCard from './carousel-card';

const carouselSettings = {
  arrows: true,
  slidesToShow: 3,
  variableWidth: true,
  dots: true,
  suppressHydrationWarning: true,
};

type ArticlesItems = NonNullable<NewsArticlesQuery['articles']>['items'];

const renderCarouselCards = (articles: ArticlesItems) => {
  if (!articles) return null;

  return articles.map((article) => (
    <CarouselCard
      key={article.id}
      id={article.id}
      title={article.title ?? ''}
      authorImageUrl={article.authors?.[0].avatarUrls?.[0].url}
      authorLink={article.authors?.[0].link}
      author={article.authors?.[0].name ?? ''}
      articleLink={article.link}
      date={article?.date ?? ''}
      readTime={article?.timeToRead?.minutes ?? 0}
    />
  ));
};

const Articles = (): JSX.Element | null => {
  const { data: contentEngagementFeatureFlag } = useFeatureFlag({
    featureFlagName: FEATURE_FLAGS.CONTENT_ENGAGEMENT_FEATURE_DATA,
  });

  const featuredArticleId =
    contentEngagementFeatureFlag?.extraData?.featured_news?.article_id;

  const { data = {}, error } = useQuery<
    NewsArticlesQuery,
    NewsArticlesQueryVariables
  >(NewsArticlesDocument, {
    variables: {
      identifier: `${featuredArticleId}`,
      exclude: [featuredArticleId],
    },
  });

  const featuredArticle = data?.article;

  if (!featuredArticle) return null;

  if (error) {
    logGqlError(error);
    return null;
  }

  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.entity_name,
        location: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.location,
      }}
    >
      <div className={styles.desktopContainer} id="desktop-article">
        <FolderTabContainer
          tabBackgroundColor="#40AD87"
          negativeTopMarginValue={30}
        >
          <div className={styles.backgroundContainer}>
            {/* Desktop Content */}
            <CenteredContainer>
              <FolderTabContainer
                tabBackgroundColor="#B7ECDA"
                contentBackgroundColor="#B7ECDA"
                contentClassName={styles.desktopArticleContainer}
                roundedCorners
              >
                <div className={styles.articleHeader}>
                  <h3 className={styles.articleHeaderTitle}>
                    Stay up to date with the latest financial news from the
                    Nerds
                  </h3>
                  <a
                    className={styles.articleHeaderLink}
                    href="https://www.nerdwallet.com/h/news/financial-news"
                  >
                    View all news
                  </a>
                </div>
                <ArticleCard
                  id={featuredArticle.id}
                  title={featuredArticle.title ?? ''}
                  imageUrl={featuredArticle.featuredMedia?.sourceUrl ?? ''}
                  authorImageUrl={
                    featuredArticle.authors?.[0].avatarUrls?.[0].url
                  }
                  authorLink={featuredArticle.authors?.[0].link}
                  author={featuredArticle.authors?.[0].name ?? ''}
                  articleLink={featuredArticle.link}
                  date={featuredArticle?.modified || featuredArticle.date || ''}
                  readTime={featuredArticle?.timeToRead?.minutes ?? 0}
                />
                <div className={styles.carouselContainer}>
                  <Carousel className="articles-carousel" {...carouselSettings}>
                    {renderCarouselCards(data?.articles?.items)}
                  </Carousel>
                </div>
              </FolderTabContainer>
            </CenteredContainer>
          </div>
        </FolderTabContainer>
      </div>

      {/* Mobile Content */}
      <div className={styles.mobileContainer}>
        <FolderTabContainer
          tabBackgroundColor="#81D6B8"
          negativeTopMarginValue={30}
        >
          <div className={styles.backgroundContainer}>
            <CenteredContainer>
              <div className={styles.articleHeader}>
                <h3 className={styles.articleHeaderTitle}>
                  Stay up to date with the latest financial news from the Nerds
                </h3>
              </div>
              <FolderTabContainer
                tabBackgroundColor="#E3FAF2"
                contentBackgroundColor="#E3FAF2"
                roundedCorners
                folderTabWidthPercentage={50}
              >
                <ArticleCard
                  id={featuredArticle.id}
                  title={featuredArticle.title ?? ''}
                  imageUrl={featuredArticle.featuredMedia?.sourceUrl ?? ''}
                  authorImageUrl={
                    featuredArticle.authors?.[0].avatarUrls?.[0].url
                  }
                  authorLink={featuredArticle.authors?.[0].link}
                  author={featuredArticle.authors?.[0].name ?? ''}
                  articleLink={featuredArticle.link}
                  date={featuredArticle?.date ?? ''}
                  readTime={featuredArticle?.timeToRead?.minutes ?? 0}
                />
              </FolderTabContainer>
            </CenteredContainer>
            <div className={styles.carouselContainer}>
              <Carousel
                className="articles-carousel"
                {...{ ...carouselSettings, slidesToShow: 1.1, arrows: false }}
              >
                {renderCarouselCards(data?.articles?.items)}
              </Carousel>
            </div>
          </div>
        </FolderTabContainer>
      </div>
    </SegmentImpression>
  );
};

export default Articles;
